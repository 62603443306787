import React, { useEffect, useRef, useState } from 'react';
import { Grid, Card, CardActionArea, CardContent, Typography, Container, CardMedia, CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useUser } from './UserContext';

const VideoOptionsGallery = () => {
  let navigate = useNavigate();
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const { user, login } = useUser();
  const options = [
    // { title: 'moodboard', video: '/videos/DarkBacc.mp4', link: '/moodboard' },
    { title: 'prompt to video', video: '/videos/AltmanQuote.mp4', link: '/promptToVideo' },
    { title: 'podcast to video', video: '/videos/ArrakisMusic.mp4', link: '/podcastToVideo' },
    { title: 'video essay', video: '/videos/DarkCrack.mp4', link: '/videoEssay' },

    // { title: 'add sound to video', video: '/videos/DuneSleeper.mp4', link: '/addSound' },
  ];

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleVideoLoad = () => {
      setVideoLoaded(true);
    };

    videoElement?.addEventListener('canplaythrough', handleVideoLoad);

    return () => {
      videoElement?.removeEventListener('canplaythrough', handleVideoLoad);
    };
  }, []);

  return (
    <Container
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ padding: '20px' }}
    >
      <Grid container spacing={4} justifyContent="center">
        {options.map((option, index) => (
          <Grid item
            xs={10}
            sm={index === 0 ? 8 : 5} // First item takes full width on small devices, next two take half
            md={index === 0 ? 8 : 5} // Similar logic for md
            key={index}
          >
            <Card
              sx={{
                margin: 'auto',
                display: 'flex',
                backgroundColor: 'black',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%', // Ensure each card has equal height
                color: 'white',
                border: '1px solid #6A6A6A',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'background-color 0.3s, box-shadow 0.3s',
              }}
              onClick={() => {
                if (!user) {
                  return;
                }
                navigate(option.link)
              }}
              variant="outlined"
            >
              <CardActionArea>
                <div style={{ position: 'relative' }}>
                  <CardMedia
                    component="video"
                    autoPlay
                    muted
                    loop
                    height={index > 1 ? 350 : 480}
                    src={option.video}
                    ref={videoRef}
                  />
                  {!videoLoaded && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <CircularProgress color="primary" size={40} />
                    </div>
                  )}
                </div>
              </CardActionArea>
              <CardContent>
                <Typography
                  variant="h5"
                  letterSpacing={2}
                  sx={{ textAlign: 'center' }}
                >
                  {option.title}
                </Typography>
              </CardContent>


            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default VideoOptionsGallery;
