import React from 'react';
import Button from '@mui/material/Button';
import { useUser } from './UserContext';
import { Typography } from '@mui/material';
const LogoutButton = () => {
  //@ts-ignore
  const {logout, user} = useUser();
  return (
    <Button
      sx={{
        color: 'white',
        border: '1px solid white',
        backgroundColor: 'black',
        '&:hover': {
          boxShadow: '0 0 10px 4px rgba(255, 255, 255, 0.5)',
          backgroundColor: 'black',
        },
      }}
      variant="contained"
    >
       <a href = "https://www.buymeacoffee.com/V5dbTWDAoX" target = "_blank" rel="noreferrer">
        <Typography variant="h7" color="white" >
        Buy me a coffee
        </Typography>
       </a>
    </Button>
  );
};

export default LogoutButton;
