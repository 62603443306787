import React, { createContext, useState, useEffect, useContext } from 'react';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/session`, { credentials: 'include' })
      .then(response => response.json())
      .then(data => setUser(data.user))
      .catch(error => console.error('Error fetching session:', error));
  }, []);

  const logout = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
      credentials: 'include' // Include credentials to send the session cookie
    })
      .then(response => {
        if (response.ok) {
          console.log("logged out")
          setUser(null)
          // Update your state to reflect that the user is now logged out
          // e.g., setUser(null), set isAuthenticated to false, etc.
        }
      })
      .catch(error => console.error('Logout failed:', error));
  }
  const login = () => {
    const serverGoogleAuthUrl = `${process.env.REACT_APP_API_URL}/auth/google`;
    window.location.href = serverGoogleAuthUrl;
  }

  return (
    //@ts-ignore
    <UserContext.Provider value={{ user, setUser, logout, login }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
