import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useUser } from './UserContext';
import SocialSignInPopup from "./SocialSignInPopup"
const TryItButton = ({text}) => {
  const {login} = useUser()
  const [open, setOpen] = useState(false)
  return (
    <>
    <Button
      sx={{
        color: 'white',
        border: '1px solid white',
        backgroundColor: 'black',
        marginRight: '20px',
        width: '200px',
        height: '50px',
        '&:hover': {
          boxShadow: '0 0 10px 4px rgba(255, 255, 255, 0.5)',
          backgroundColor: 'black',
        },
      }}
      variant="contained"
      onClick={()=>setOpen(true)}
    >
        {text ? text : "Make a video"}      
    </Button>
    <SocialSignInPopup open={open} handleClose={()=>setOpen(false)}/>
    </>
  );
};

export default TryItButton;
