import React from 'react';
import { Dialog, DialogContent, Button, Box } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google'; // Importing Google icon
import { useUser } from './UserContext';

function SocialSignInPopup({ open, handleClose }) {
    const { login } = useUser();
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent
                sx={{ backgroundColor: 'black', border: '1px solid white' }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: 200 }} // Adjust height as needed
                >
                    <Button
                        variant="contained"
                        onClick={() => login()}
                        sx={{
                            color: 'white',
                            border: '1px solid white',
                            backgroundColor: 'black',
                            '&:hover': {
                                boxShadow: '0 0 10px 4px rgba(255, 255, 255, 0.5)',
                                backgroundColor: 'black',
                            },
                        }}
                        startIcon={<GoogleIcon />} // Add the Google icon here
                    >
                        google
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={true}
                        onClick={() => login()}
                    >
                        twitter (coming soon)
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default SocialSignInPopup;
