import React, { useEffect, useState } from 'react';
import { Button, TextField, Input, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, InputLabel, LinearProgress, CircularProgress, Typography } from '@mui/material';
import "./PodcastToVideo.css"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PopupVideoComponent from './PopupVideoComponent';
const Moodboard = () => {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [theme, setTheme] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [progress, setProgress] = useState(0);
    const [videoURL, setVideoURL] = useState(null);
    const [loading, setLoading] = useState(false);
    const [jobId, setJobId] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let body = {
            text: text,
            title: title,
            theme: theme
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/generateMoodboard`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setLoading(true);
        const data = await response.json();
        if (data.success) {
            setVideoURL(data.url)
            setJobId(data.jobID);
            console.log("set job id", jobId)
        } else {
            setLoading(false);
            alert('Failed to process video.');
        }
    };

    useEffect(() => {
        if (jobId) {
            // Set up a polling interval to check the job status every 10 seconds
            const intervalId = setInterval(async () => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/jobStatus/${jobId}/moodBoard`);
                const data = await response.json();
                setProgress(data.result.progress)
                console.log("got data", data)
                if (data.result.state === 'completed') {
                    console.log("enetered", data.result.state)
                    // If the job is complete, stop polling and set the video URL
                    clearInterval(intervalId);
                    setLoading(false);
                    setOpenDialog(true);
                    setProgress(0);
                } else if (data.result.state === 'failed') {
                    // If the job has failed, stop polling and alert the user
                    clearInterval(intervalId);
                    setLoading(false);
                    setProgress(0);
                    alert('Video generation failed. Please try again.');
                }
            }, 10000);
            return () => clearInterval(intervalId);  // Clean up the interval on component unmount
        }
    }, [jobId]);

    return (
        <Box
            className="container"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '80%',
            }}
        >
            <ArrowBackIosIcon sx={{ color: 'white', marginTop: '5%', marginBottom: '5%', marginRight: '100%' }} onClick={() => window.history.back()} />

            <Typography variant="h2" color="white" sx={{ flexGrow: 1, marginBottom: '2%' }}>
                make a mood board...
            </Typography>
            <a href={'https://youtube.com/shorts/53C1ZKGivp4'} target='_blank' rel="noopener noreferrer"> 
            <Typography variant="h4" color="white" sx={{ flexGrow: 1, padding: '2%' }}>
                example
            </Typography>
            </a>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Theme"
                    placeholder='Theme used to generate images (eg. Dark academia, Autumn in Paris)'
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={theme}
                    onChange={(e) => setTheme(e.target.value)}
                    sx={{
                        mb: 2,
                        background: 'black', // Background color
                        color: 'white',
                        borderRadius: '8px',
                        '& input': {
                            caretColor: 'white', // Cursor color
                            color: 'white', // Text color
                        },
                        '& label': {
                            color: 'white', // Label color
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'white', // Outline color
                            },
                            '&:hover fieldset': {
                                borderColor: 'white', // Hover outline color
                            },
                        },
                    }}
                />
                <TextField
                    label="Title"
                    placeholder='Title..'
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{
                        mb: 2,
                        background: 'black', // Background color
                        color: 'white',
                        borderRadius: '8px',
                        '& input': {
                            caretColor: 'white', // Cursor color
                            color: 'white', // Text color
                        },
                        '& label': {
                            color: 'white', // Label color
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'white', // Outline color
                            },
                            '&:hover fieldset': {
                                borderColor: 'white', // Hover outline color
                            },
                        },
                    }}
                />
                <TextField
                    label="Text to display"
                    placeholder='Text that will display throughout video (eg. peace, calm)'
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    sx={{
                        mb: 2,
                        background: 'black', // Background color
                        color: 'white',
                        borderRadius: '8px',
                        '& input': {
                            caretColor: 'white', // Cursor color
                            color: 'white', // Text color
                        },
                        '& label': {
                            color: 'white', // Label color
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'white', // Outline color
                            },
                            '&:hover fieldset': {
                                borderColor: 'white', // Hover outline color
                            },
                        },
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Button
                        sx={{
                            mb: 2,
                            marginTop: '30px',
                            width: '40%',
                            color: 'white',
                            border: '1px solid white',
                            backgroundColor: 'black', // Background color
                            '&:hover': {
                                boxShadow: '0 0 10px 4px rgba(255, 255, 255, 0.5)', // White glow on hover
                                backgroundColor: 'black', // Reset background color
                            },
                        }}
                        variant="contained"
                        type="submit"
                    >
                        {loading ? <CircularProgress /> : 'Generate Video'}
                    </Button>
                    {loading && (
                        <Box sx={{ width: '40%' }}>
                            <LinearProgress
                                variant="determinate"
                                value={progress}
                                sx={{
                                    width: '100%', // Adjust the width as needed
                                    height: '10px', // Adjust the height to make it thicker
                                    borderRadius: '8px',
                                    backgroundColor: 'white', // Background color
                                }}
                            />
                        </Box>
                    )}
                    {videoURL && <div>Your video will be available soon at {videoURL}</div>}
                </Box>
            </form>
            <PopupVideoComponent openDialog={openDialog} setOpenDialog={setOpenDialog} videoURL={videoURL} loading={loading} title = {title}></PopupVideoComponent>
        </Box>
    );
};

export default Moodboard;

