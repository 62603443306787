import React, { useEffect, useRef, useState } from 'react';
import { Grid, Card, CardActionArea, CardContent, Typography, Container, CardMedia, CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import PopupVideoComponent from './PopupVideoComponent';



const Generations = () => {
  let navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [generationDetails, setGenerationDetails] = useState(null);
  // export type UserGenerationDTO = {
  //   thumbnail: string,
  //   title: string,
  //   video: string,
  //   text: string,
  //   gallery: [],
  //   music: [],
  //   podcast: []
  // }
  const fetchVideos = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/user/generations`, {
      credentials: 'include',
    });
    const data = await response.json();
    console.log("data", data)
    setVideos(data.result.display);
  }
  useEffect(() => {
    fetchVideos();
  },[])
  

  const isPhoneScreen = window.innerWidth <= 600;

  return (
    <Container
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        flexDirection: 'column',
        height: '100%',
        marginTop: '40px',
      }}
    >
      <Grid container spacing={2} rowSpacing={3} justifyContent="center">
        {videos.length===0 ? (
        <CircularProgress />
        ) : (
          videos?.map((option, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  margin: 'auto',
                  display: 'flex',
                  backgroundColor: 'black',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  border: '1px solid #6A6A6A',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                  transition: 'background-color 0.3s, box-shadow 0.3s',
                }}
                onClick={() => {
                  setOpenDialog(true);
                  setGenerationDetails(option)
                  console.log("option", option)
                }}
                variant="outlined"
              >
                <CardActionArea>
                  <div style={{ position: 'relative' }}>
                    <CardMedia
                      component="img"
                      height={400}
                      src={option.thumbnail}
                    />
                  </div>
                  <CardContent>
                    <Typography
                      variant="h5"
                      letterSpacing={2}
                      sx={{ textAlign: 'center', flexGrow: 1 }}
                    >
                      {option.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))
          
        )}
      </Grid>
      <PopupVideoComponent openDialog={openDialog} setOpenDialog={setOpenDialog} videoURL={generationDetails?.video} loading={false} title = {generationDetails?.title}></PopupVideoComponent>
    </Container>
  );
};

export default Generations;
