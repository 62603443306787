import React, { useState } from 'react';
import { Select, MenuItem, Box, Typography } from '@mui/material';
import VideoOptionsGallery from './VideoOptionsGallery';
import "../App.css"
import Header from './Header';
import TryItButton from './TryItButton'
import {useUser} from './UserContext'
function Home() {
    const { user } = useUser();
    console.log("u", user)
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: '150px' }}>        
                <Header />
                <Typography variant="h2" color="white" sx={{ padding: "20px"}}>
                    make videos with just text input
                </Typography>
                {user ? (
                    <Typography variant="h5" color="white" sx={{ padding: "20px"}}>
                        click on a video to get started, {truncateString(user.email, 6)}
                    </Typography>
                ) : (
                <TryItButton />
                )}
                <VideoOptionsGallery/>
        </ Box>
    );
}

function truncateString(str, length) {
    if (str.length <= length) {
        return str;
    }
    return str.slice(0, length) + '...';
}

export default Home;
