import React, { useEffect, useState } from 'react';
import { Button, Select, TextField, Input, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, InputLabel, LinearProgress, CircularProgress, Grid, Typography, MenuItem } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PopupVideoComponent from './PopupVideoComponent';

const PodcastToVideo = () => {
    const [title, setTitle] = useState('');
    const [audioFile, setAudioFile] = useState(null);
    const [theme, setTheme] = useState('');
    const [soundAdded, setSoundAdded] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [jobId, setJobId] = useState(null);
    const [dimensions, setDimensions] = useState('Youtube Fullscreen');


    const handleDimensionschange = (e) => {
        setDimensions(e.target.value);
    }

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData();
        formData.append('audioFile', audioFile);
        formData.append('theme', theme);
        formData.append('title', title);
        formData.append('dimensions', dimensions);
        setLoading(true);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/podcastToVideo`, {
            method: 'POST',
            body: formData,
            credentials: 'include' // Include credentials to send the session cookie
        });
        const data = await response.json();
        if (data.success) {
            setSoundAdded(data.url);
            setJobId(data.jobID);
            console.log("set job id", jobId)
        } else {
            setLoading(false);
            alert('Failed to process video.');
        }
    };

    useEffect(() => {
        if (jobId) {
            // Set up a polling interval to check the job status every 10 seconds
            const intervalId = setInterval(async () => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/jobStatus/${jobId}/podcastToVideo`);
                const data = await response.json();
                setProgress(data.result.progress)
                console.log("got data", data)
                if (data.result.state === 'completed') {
                    console.log("enetered", data.result.state)
                    // If the job is complete, stop polling and set the video URL
                    clearInterval(intervalId);
                    setLoading(false);
                    setOpenDialog(true);
                } else if (data.result.state === 'failed') {
                    // If the job has failed, stop polling and alert the user
                    clearInterval(intervalId);
                    setLoading(false);
                    setProgress(0);
                    alert('Video generation failed. Please try again.');
                }
            }, 10000);
            return () => clearInterval(intervalId);  // Clean up the interval on component unmount
        }
    }, [jobId]);

    return (
        <Box
            className="container"
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '70%' }}
        >
            <ArrowBackIosIcon sx={{ color: 'white', marginTop: '5%', marginBottom: '5%', marginRight: '90%' }} onClick={() => window.history.back()} />

            <Typography variant="h2" color="white" sx={{ flexGrow: 1, marginBottom: '1%' }}>
                podcast or song to video
            </Typography>
            <a href={'https://youtu.be/sqVWT9cY5Ks'} target='_blank' rel="noopener noreferrer">
                <Typography variant="h4" color="white" sx={{ flexGrow: 1, marginBottom: '5%' }}>
                    example
                </Typography>
            </a>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Theme"
                    placeholder='Theme to assist image generation..(eg. Winterfell, Dark academia)'
                    fullWidth
                    name="Theme"
                    margin="normal"
                    variant="outlined"
                    value={theme}
                    onChange={(e) => setTheme(e.target.value)}
                    sx={{
                        mb: 2,
                        background: 'black', // Background color
                        color: 'white',
                        borderRadius: '8px',
                        '& input': {
                            caretColor: 'white', // Cursor color
                            color: 'white', // Text color
                        },
                        '& label': {
                            color: 'white', // Label color
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'white', // Outline color
                            },
                            '&:hover fieldset': {
                                borderColor: 'white', // Hover outline color
                            },
                        },
                    }}
                />
                <TextField
                    label="Title"
                    placeholder='Title of the video..'
                    fullWidth
                    name="Title"
                    margin="normal"
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{
                        mb: 2,
                        background: 'black', // Background color
                        color: 'white',
                        borderRadius: '8px',
                        '& input': {
                            caretColor: 'white', // Cursor color
                            color: 'white', // Text color
                        },
                        '& label': {
                            color: 'white', // Label color
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'white', // Outline color
                            },
                            '&:hover fieldset': {
                                borderColor: 'white', // Hover outline color
                            },
                        },
                    }}
                />
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <InputLabel sx={{ color: 'white', marginBottom: '5px' }} name="upload-sound-file" // Add this line
                        htmlFor="upload-sound-file">
                        <Typography variant="h5" color="white" sx={{ flexGrow: 1, marginBottom: '5%', letterSpacing: 0.5 }}>
                            add the podcast/song audio:
                        </Typography>
                    </InputLabel>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <label htmlFor="upload-sound-file" style={{ cursor: 'pointer' }}>
                            <Button variant="outlined" component="span">
                                {audioFile ? (audioFile.name) : ("Choose File")}
                            </Button>
                            <Input
                                type="file"
                                id="upload-sound-file"
                                accept="audio/*"
                                onChange={(e) => setAudioFile(e.target.files[0])}
                                style={{ display: 'none' }}
                                required
                            />
                        </label>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            marginTop: '5%',
                            display: 'flex',
                            justifyContent: 'center',
                            color: 'white',
                        }}
                    >
                        <Select
                            fullWidth
                            name="dimensions"
                            sx={{
                                mb: 2,
                                width: '60%',
                                color: 'white',
                                '& input': {
                                    caretColor: 'white', // Cursor color
                                    color: 'white', // Text color
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                },
                                '&::after': {
                                    borderBottom: '2px solid red'
                                },
                                '&::before': {
                                    borderBottom: '2px solid yellow'
                                },
                                '& label': {
                                    color: 'white', // Label color
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'white', // Outline color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white', // Hover outline color
                                    },
                                },
                            }}
                            id="outlined-basic"
                            value={dimensions}
                            onChange={handleDimensionschange}
                            label="Dimensions"
                            placeholder="Dimensions of video..." // Placeholder text
                            variant="outlined"
                        >
                            <MenuItem value={'1024x1792'}>Tiktok/Shorts</MenuItem>
                            <MenuItem value={'1792x1024'}>Youtube Fullscreen</MenuItem>
                        </Select>
                    </Box>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        sx={{
                            marginTop: '30px',
                            color: 'white',
                            border: '1px solid white',
                            backgroundColor: 'black', // Background color
                            '&:hover': {
                                boxShadow: '0 0 10px 4px rgba(255, 255, 255, 0.5)', // White glow on hover
                                backgroundColor: 'black', // Reset background color
                            },
                        }}
                        disabled={loading && true}
                        variant="contained"
                        type="submit"
                    >
                        {loading ? <CircularProgress /> : 'Generate Video'}
                    </Button>
                    {loading && (
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                                width: '100%', // Adjust the width as needed
                                height: '8px', // Adjust the height to make it thicker
                                marginTop: '20px',
                                borderRadius: '8px',
                                backgroundColor: 'white', // Background color
                            }}
                        />
                    )}
                </Box>
                {soundAdded && <div>Your video will be available soon at {soundAdded}</div>}
            </form>
            <PopupVideoComponent openDialog={openDialog} setOpenDialog={setOpenDialog} videoURL={soundAdded} loading={loading} title={title}></PopupVideoComponent>
        </Box>

    );
};

export default PodcastToVideo;

