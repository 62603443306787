import React from 'react';
import TryItButton from './TryItButton'; // Import your Google login button component
import "./Header.css"
import { useUser } from './UserContext';
import LogoutButton from './LogoutButton';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
const Header = () => {
    //@ts-ignore
    const { user } = useUser();
    const navigate = useNavigate()
    console.log("got", user)
    return (
        <div className="header">
            <div className="logo-container">
                <img src={'/videos/mid.png'} alt="Logo" />
            </div>
            <Box>
                {user ? (<Button sx={{
                        color: 'white',
                        border: '1px solid white',
                        backgroundColor: 'black',
                        marginLeft: '100px',
                        marginRight: '20px',
                        '&:hover': {
                        boxShadow: '0 0 10px 4px rgba(255, 255, 255, 0.5)',
                        backgroundColor: 'black',
                        },
                    }}
                    variant="contained"
                    onClick={()=>navigate('/generations')}>MY VIDEOS</Button>) : (<div />)}
            </Box>
            <div className="login-button-container">
                {user ? (<LogoutButton />) : (<TryItButton text = {"LOGIN"}/>)}
            </div>
        </div>
    );
};

export default Header;
