import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Switch,
  CircularProgress,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const AddSoundToVideo = () => {
  const [title, setTitle] = useState('');
  const [audioFile, setAudioFile] = useState(null);
  const [videoURL, setVideoURL] = useState('');
  const [soundAdded, setSoundAdded] = useState(null);
  const [hasAudio, setHasAudio] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobId, setJobId] = useState(null);
  const fileInputRef = React.useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!title || !audioFile || !videoURL) {
      alert('Please fill in all required fields.');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('audioFile', audioFile);
    formData.append('videoURL', videoURL);
    formData.append('title', title);
    formData.append('doesVideoHaveAudio', hasAudio);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/addSound`, {
      method: 'POST',
      body: formData,
    });

    setOpenDialog(true);
    resetForm();

    const data = await response.json();
    if (data.success) {
      setSoundAdded(data.url);
      setJobId(data.jobID);
      setOpenDialog(true);
    } else {
      setOpenDialog(false);
      setLoading(false);
      alert('Failed to process video.');
    }
  };

  const resetForm = () => {
    setTitle('');
    setAudioFile(null);
    setVideoURL('');
  };

  useEffect(() => {
    if (jobId) {
      // Set up a polling interval to check the job status every 10 seconds
      const intervalId = setInterval(async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/jobStatus/${jobId}/sound`);
        const data = await response.json();
        if (data.result.state === 'completed') {
          // If the job is complete, stop polling and set the video URL
          clearInterval(intervalId);
          setLoading(false);
        } else if (data.result.state === 'failed') {
          // If the job has failed, stop polling and alert the user
          clearInterval(intervalId);
          setLoading(false);
          alert('Video generation failed. Please try again.');
        }
      }, 1000);
      return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }
  }, [jobId]);

  const handleFileInputChange = (event) => {
    setAudioFile(event.target.files[0]);
  };

  return (
    <Box
      className="container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
      }}
    >   
    <ArrowBackIosIcon sx={{ color: 'white', marginTop: '5%', marginBottom: '5%', marginRight: '100%' }} onClick={() => window.history.back()} />
          <Typography variant="h3" color="white" sx={{ flexGrow: 1, marginBottom: '5%', justifyContent: 'center' }}>
        add sound to vid
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="video url"
              placeholder='https://www.youtube.com/watch?v=...'
              fullWidth
              margin="normal"
              variant="outlined"
              value={videoURL}
              onChange={(e) => setVideoURL(e.target.value)}
              required
              sx={{
                mb: 2,
                background: 'black', // Background color
                color: 'white',
                borderRadius: '8px',
                '& input': {
                  caretColor: 'white', // Cursor color
                  color: 'white', // Text color
                },
                '& label': {
                  color: 'white', // Label color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white', // Outline color
                  },
                  '&:hover fieldset': {
                    borderColor: 'white', // Hover outline color
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="title"
              placeholder='My Video..'
              fullWidth
              margin="normal"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              sx={{
                mb: 2,
                background: 'black', // Background color
                color: 'white',
                borderRadius: '8px',
                '& input': {
                  caretColor: 'white', // Cursor color
                  color: 'white', // Text color
                },
                '& label': {
                  color: 'white', // Label color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white', // Outline color
                  },
                  '&:hover fieldset': {
                    borderColor: 'white', // Hover outline color
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <InputLabel sx={{ color: 'white', marginBottom: '5px' }} name="upload-sound-file" // Add this line
              htmlFor="upload-sound-file">
              <Typography variant="h6" color="white" sx={{ flexGrow: 1, marginBottom: '5%', letterSpacing: 0.5 }}>
                audio file:
              </Typography>
            </InputLabel>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <label htmlFor="upload-sound-file" style={{ cursor: 'pointer' }}>
                <Button variant="outlined" component="span">
                  {audioFile ? (audioFile.name) : ("Choose File")}
                </Button>
                <Input
                  type="file"
                  id="upload-sound-file"
                  accept="audio/*"
                  onChange={(e) => setAudioFile(e.target.files[0])}
                  style={{ display: 'none' }}
                  required
                />
              </label>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <FormGroup>
              <FormControlLabel
                control={<Switch defaultChecked onChange={() => setHasAudio(!hasAudio)} />}
                label={<span style={{ color: 'white' }}>does video already have audio? (turn on for yes)</span>}
                labelPlacement="top"
                sx={{
                  '& .MuiSwitch-root': {
                    color: 'white', // Color of the switch itself
                  },
                }}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              sx={{
                marginTop: '30px',
                color: 'white',
                border: '1px solid white',
                backgroundColor: 'black', // Background color
                '&:hover': {
                  boxShadow: '0 0 10px 4px rgba(255, 255, 255, 0.5)', // White glow on hover
                  backgroundColor: 'black', // Reset background color
                },
              }}
              type="submit"
            >
              {loading ? <CircularProgress /> : 'Add Sound'}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        {loading ? (
          <>
            <DialogContent>Loading...</DialogContent>
          </>
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                Sound has been added successfully!
                <br />
                Sound URL: <a href={soundAdded}>Go to Video</a>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default AddSoundToVideo;
