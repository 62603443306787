import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import VideoGenerator from './components/VideoGenerator';
import Home from "./components/Home"
import Moodboard from './components/Moodboard';
import PodcastToVideo from './components/PodcastToVideo';
import AddSoundToVideo from './components/AddSoundToVideo';
import { UserProvider }  from './components/UserContext';
import PromptToVideo from './components/PromptToVideo';
import Generations from './components/Generations';

function App() {
    return (
    <div className="App">
      <UserProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/videoEssay" element={<VideoGenerator />} />
            <Route path="/promptToVideo" element={<PromptToVideo />} />
            <Route path="/generations" element={<Generations />} />
            <Route path="/moodboard" element={<Moodboard />} />
            <Route path="/podcastToVideo" element={<PodcastToVideo />} />
            <Route path="/addSound" element={<AddSoundToVideo />} />
          </Routes>
        </Router>
      </UserProvider>
    </div>               
    );
}

export default App;
