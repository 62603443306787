import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField, Box, LinearProgress, Typography, Select, MenuItem, InputLabel } from '@mui/material';
import React, { useState, useEffect } from 'react';
import './VideoGenerator.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PopupVideoComponent from './PopupVideoComponent';

function PromptToVideo() {
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [theme, setTheme] = useState('');
  const [videoURL, setVideoURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [dimensions, setDimensions] = useState('1024x1024');

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleDimensionschange = (e) => {
    setDimensions(e.target.value);
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };

  const handleSubmit = async () => {
    let body = {
      prompt: text,
      title: title,
      theme: theme,
      dimensions: dimensions
    }
    console.log("BODY", body)
    setLoading(true);
    setProgress(3);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/promptToVideo`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include' // Include credentials to send the session cookie
    });
    const data = await response.json();
    if (data.success === false) {
      alert(data.message);
      setLoading(false);
      return;
    }
    console.log(data);
    // Store the job ID for polling
    setJobId(data.jobID);
    setVideoURL(data.url)
  };
  const isMobile = window.innerWidth <= 600; // Adjust the breakpoint as needed


  useEffect(() => {
    if (jobId) {
      // Set up a polling interval to check the job status every 10 seconds
      const intervalId = setInterval(async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/jobStatus/${jobId}/video`, {
          credentials: 'include' // Include credentials to send the session cookie
        });
        const data = await response.json();
        setProgress(data.result.progress)
        console.log("got data", data)
        if (data.result.state === 'completed') {
          // If the job is complete, stop polling and set the video URL
          clearInterval(intervalId);
          setLoading(false);
          setProgress(0);
          setOpenDialog(true);
        } else if (data.result.state === 'failed') {
          // If the job has failed, stop polling and alert the user
          clearInterval(intervalId);
          setLoading(false);
          setProgress(0);
          alert('Video generation failed. Please try again.');
        }
      }, 10000);
      return () => clearInterval(intervalId);  // Clean up the interval on component unmount
    }
  }, [jobId]);

  return (
    <Box className="container" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ArrowBackIosIcon
        sx={{
          color: 'white',
          marginTop: isMobile ? '5%' : '0', // Add margin only on mobile
          marginBottom: '5%',
          padding: '2%',
          marginRight: 'auto', // Auto margin on desktop
        }}
        onClick={() => window.history.back()}
      />
      <Typography variant="h2" color="white" sx={{ flexGrow: 1, padding: '2%' }}>
        write a prompt for a video...
      </Typography>
      <a href={'https://youtube.com/shorts/IlR4xd6wdyo'} target='_blank' rel="noopener noreferrer">
        <Typography variant="h4" color="white" sx={{ flexGrow: 1, marginBottom: '5%' }}>
          example
        </Typography>
      </a>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TextField
              required={true}
              sx={{
                mb: 2,
                width: '60%',
                background: 'black', // Background color
                color: 'white',
                borderRadius: '8px',
                '& input': {
                  caretColor: 'white', // Cursor color
                  color: 'white', // Text color
                },
                '& label': {
                  color: 'white', // Label color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white', // Outline color
                  },
                  '&:hover fieldset': {
                    borderColor: 'white', // Hover outline color
                  },
                },
              }}
              id="outlined-basic"
              value={title}
              onChange={handleTitleChange}
              label="Title"
              placeholder="Title.." // Placeholder text
              InputProps={{ style: { color: 'white' } }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TextField
              required={true}
              sx={{
                mb: 2,
                width: '60%',
                '& input': {
                  caretColor: 'white', // Cursor color
                  color: 'white', // Text color
                },
                '& label': {
                  color: 'white', // Label color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white', // Outline color
                  },
                  '&:hover fieldset': {
                    borderColor: 'white', // Hover outline color
                  },
                },
              }}
              id="outlined-basic"
              value={theme}
              onChange={handleThemeChange}
              label="Theme"
              placeholder="Theme to assist image generation..(eg. Winterfell, Dark academia)" // Placeholder text
              variant="outlined"
              InputProps={{ style: { color: 'white' } }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              color: 'white',
            }}
          >
            <Select
              fullWidth
              sx={{
                mb: 2,
                width: '60%',
                color: 'white',
                '& input': {
                  caretColor: 'white', // Cursor color
                  color: 'white', // Text color
                },
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '.MuiSvgIcon-root ': {
                  fill: "white !important",
                },
                '&::after': {
                  borderBottom: '2px solid red'
                },
                '&::before': {
                  borderBottom: '2px solid yellow'
                },
                '& label': {
                  color: 'white', // Label color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white', // Outline color
                  },
                  '&:hover fieldset': {
                    borderColor: 'white', // Hover outline color
                  },
                },
              }}
              id="outlined-basic"
              value={dimensions}
              onChange={handleDimensionschange}
              label="Dimensions"
              required={true}
              placeholder="Dimensions of video..." // Placeholder text
              variant="outlined"
            >
              <MenuItem value={'1024x1792'}>Tiktok/Shorts</MenuItem>
              <MenuItem value={'1792x1024'}>Youtube Fullscreen</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TextField
              required={true}
              sx={{
                mb: 2,
                width: '60%',
                '& input': {
                  caretColor: 'white', // Cursor color
                  color: 'white', // Text color
                },
                '& label': {
                  color: 'white', // Label color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white', // Outline color
                  },
                  '&:hover fieldset': {
                    borderColor: 'white', // Hover outline color
                  },
                },
              }}
              id="outlined-basic"
              value={text}
              onChange={handleTextChange}
              label="Type your instruction here.."
              placeholder="Generate a short video on the dark ages" // Placeholder text
              variant="outlined"
              multiline
              rows={4}
              InputProps={{ style: { color: 'white' } }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column', // Stack elements vertically on mobile
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                mb: isMobile ? 2 : 0, // Add margin only on mobile
                color: 'white',
                border: '1px solid white',
                backgroundColor: 'black',
                '&:hover': {
                  boxShadow: '0 0 10px 4px rgba(255, 255, 255, 0.5)',
                  backgroundColor: 'black',
                },
              }}
              disabled={loading}
              variant="contained"
              onClick={handleSubmit}
            >
              {loading ? <CircularProgress /> : 'Generate Video'}
            </Button>
          </Box>
        </Grid>

        {loading && (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  width: '60%', // Adjust the width as needed
                  height: '10px', // Adjust the height to make it thicker
                  borderRadius: '8px',
                  backgroundColor: 'white',
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <PopupVideoComponent openDialog={openDialog} setOpenDialog={setOpenDialog} videoURL={videoURL} loading={loading} title={title}></PopupVideoComponent>
    </Box>


  );
}

export default PromptToVideo;
